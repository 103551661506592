.section-chose-member {
    .member-list {
        margin: 1rem 0;
        & li {
            padding: 1rem;
            display: flex;
            align-items: center;
            border-left: 5px solid transparent;
            border-bottom: 1px solid $grey-lighter;

            &:hover {
                border-left-color: $primary;
                .buttons {
                    display: block;
                }
            }

            .member-information {
                flex-grow: 1
            }
            .buttons {
                display: none;
            }
        }
    }
}
