@charset "utf-8";

// Set your brand colors
$primary: #7ca55b;
$info: #ef7d36;
$link: #2199e8;
$warning: #FFAF40;
$danger: #E23600;
$dark: #242325;
$success: #7ca55b;

// Custom variable definitions
$grey-lighter: #DDDDDD;
$grey-lightest: #EEEEEE;
$transition-short: 0.2s;

// Update Bulma's global variables
@use "../node_modules/bulma/sass" with (
    $body-background-color: $grey-lightest,
    $family-sans-serif: '"Oswald", sans-serif',
    $widescreen-enabled: true,
    $fullhd-enabled: false,
    $radius: 0,
    $primary: $primary,
    $info: $info,
    $link: $link,
    $danger: $danger,
    $dark: $dark,
    $success: $success,
    $button-text-color: white,
    $card-radius: 0,

    $navbar-height: 6rem,
    $navbar-item-img-max-height: 5rem,

    // Have wider modals
    $modal-content-width: 900px
);

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Fjalla+One|Oswald:400,700');

@import "./styles/header.scss";
@import "./styles/choseMember.scss";
@import "./styles/dashboard.scss";
@import "./styles/loadingSpinner.scss";
@import "./styles/invoice.scss";

.title {
    color: #181818;
    font-family: "Fjalla One", sans-serif;
    text-transform: uppercase;
}

.container.narrow {
    max-width: 700px;
}

iframe {
    max-width: 800px;
    width: 100%;
    height: 600px;
    margin: auto;
}

input.is-loading,
input.is-loading[disabled] {
    cursor: progress;
}

.button.is-primary {
    color: white;
}