@media print {
    @page {
        size: A4 portrait;
        margin: 2cm;
    }

    .container {
        max-width: none;
        padding: 0;
    }
    .card {
        box-shadow: none;
    }
    .print--hide {
        display: none;
    }
    .site-header {
        display: none;
    }
}

.invoice {
    @media print {
        --text-size: 14px;
        --headline-size: 30px;
        --line-spacing: 5px;
        --totals-width: 200px;
    }
    @media screen {
        --text-size: 1rem;
        --headline-size: 2rem;
        --line-spacing: 0.5rem;
        --totals-width: 20rem;
        padding: 10rem 10rem;
    }
    @media screen and (min-width: 1025px) {
        min-height: 80rem;
    }
    @media screen and (max-width: 1024px) {
        padding: 10rem 5rem;
    }
    @media screen and (max-width: 768px) {
        padding: 5rem 2rem;
        --totals-width: auto;
    }

    flex-direction: column;
    background-color: white;
    color: black;
    display: flex !important;
    font-size: var(--text-size);

    .invoice--logo {
        display: flex;
        justify-content: flex-end;
        font-size: var(--headline-size);

        img {
            margin-left: 20px;
            height: 60px;
        }
    }

    .invoice--customer-details,
    .invoice--company-details {
        p {
            margin-bottom: var(--line-spacing);
        }
    }
    .invoice--company-details {
        text-align: right;
    }
    .invoice--from-to {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
    }

    .invoice--section-divider {
        display: flex;
        justify-content: flex-end;
        position: relative;
        font-size: var(--headline-size);
        &::before {
            content: " ";
            display: block;
            position: absolute;
            border-top: 2px solid black;
            left: 0;
            right: 0;
            bottom: var(--line-spacing);
            z-index: 1;
        }
        p {
            max-width: var(--totals-width);
            flex-grow: 1;
            padding-bottom: var(--line-spacing);
            z-index: 2;
            position: relative;

            &::before {
                content: " ";
                display: block;
                position: absolute;
                right: 100%;
                top: 0;
                bottom: 0;
                width: 20px;
                background-color: white;
                z-index: 2;
            }
        }
    }

    .invoice--invoice-details {
        width: var(--totals-width);
        align-self: flex-end;
        margin-bottom: 80px;

        table {
            width: 100%;
            td {
                margin-bottom: var(--line-spacing);
                margin-right: var(--line-spacing);
                
                &:first-child {
                    position: relative;
                    &::before {
                        content: " ";
                        display: block;
                        position: absolute;
                        left: 0;
                        right: var(--line-spacing);
                        bottom: var(--line-spacing);
                        border-top: 2px dotted black;
                        z-index: 1;
                    }
                    span {
                        background-color: white;
                        z-index: 2;
                        position: relative;
                        padding-right: var(--line-spacing);
                    }
                }
            }
        }
    }

    .invoice--invoice-lines {
        margin-bottom: 20px;

        table {
            width: 100%;
            th {
                border-top: 2px solid black;
                border-bottom: 2px solid black;
            }
            td {
                margin-bottom: var(--line-spacing);
                margin-right: var(--line-spacing);
            }
        }
    }

    .invoice--totals {
        width: var(--totals-width);
        align-self: flex-end;
        margin-bottom: 20px;

        table {
            width: 100%;
            td {
                margin-bottom: var(--line-spacing);
                margin-right: var(--line-spacing);
                &:nth-child(2) {
                    text-align: right;
                }
            }

            tr:last-child {
                border-top: 2px solid black;
                border-bottom: 2px solid black;
            }
        }
    }
}