.site-header {
    & .navbar.is-dark {
        & .navbar-start > .navbar-item,
        & .navbar-end > .navbar-item,
        & .navbar-brand > .navbar-item {
            color: white;
            transition: color $transition-short;
        }

        & .navbar-start > a.navbar-item:hover,
        & .navbar-end > a.navbar-item:hover,
        & .navbar-brand > a.navbar-item:hover {
            color: $primary;
            background-color: transparent;
        }
    }
    & .navbar-item {
        font-weight: bold;
        text-transform: uppercase;
    }

    & .flag-select button {
        border: 1px solid rgba(255, 255, 255, 0.3) !important;
        &::after {
            border-top-color: rgba(255, 255, 255, 0.5);
            border-bottom-color: rgba(255, 255, 255, 0.5);
        }
        &:active {
            background-color: transparent;
        }
    }
}